import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Library from './components/Library';
import Viewer from './components/Viewer';
import Home from './components/Home';
import Register from './components/Register';
import ConfirmEmail from './components/ConfirmEmail';
import GroupHome from './components/GroupHome';
import Profile from './components/Profile';
import Waitlist from './components/WaitlistV2';
import About from './components/About';
import { renewToken } from './services/api/authentication';
import RequestPasswordReset from './components/RequestPasswordReset';
import PasswordReset from './components/PasswordReset'
import Messages from './components/Messages';
import Admin from './components/Admin';
import AdminUsers from './components/AdminUsers';
import AdminUser from './components/AdminUser';
import AdminGroups from './components/AdminGroups';
import AdminGroup from './components/AdminGroup';
import ProtectedAdminRoute from './components/ProtectedAdminRoute';
import AdminGroupMembers from './components/AdminGroupMembers';
import AdminGroupMember from './components/AdminGroupMember';
import AdminAgreements from './components/AdminAgreements';
import AdminAgreement from './components/AdminAgreement';
import AdminAgreementVersions from './components/AdminAgreementVersions';
import AdminAgreementVersion from './components/AdminAgreementVersion';
import AdminOrganizations from './components/AdminOrganizations';
import AdminOrganization from './components/AdminOrganization';
import AdminOrganizationUsers from './components/AdminOrganizationUsers';
import AdminOrganizationUser from './components/AdminOrganizationUser';
import AdminTags from './components/AdminTags.js';
import AdminTag from './components/AdminTag.js';
import AdminMetrics from './components/AdminMetrics.js';
import { gtag_id, gtag_config, mixpanel_id, mixpanel_config} from './sources.js';
import { setUser } from "./services/redux/userSlice.js";
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import LandingPage from './store/pages/LandingPage.js';
import ForTeams from './store/pages/ForTeams.js';
import ForMedTechs from './store/pages/ForMedTechs.js';
import MoreInfoRedirect from './components/MoreInfoRedirect.js';
import AppLayout from './components/AppLayout';
import ExportDownload from './components/ExportDownload';
import Editor from './components/Editor';
import PublicShare from './components/PublicShare.js';

function App() {

  const mounted = React.useRef(true);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.value);

  //useEffect hook to refresh the user's jwt token every 30 minutes
  useEffect(() => {
      console.log('setting jwt renewal interval')
      const interval = setInterval(() => {
        console.log('checking jwt renewal interval')
        if (user) {
            console.log('renewing jwt token for user ' + user.id)
            renewToken();
        }
      }, 1800000);
    return () => {clearInterval(interval); console.log('clearing jwt renewal interval')}
  }, [user])

  //useEffect hook to set tracker IDs
  useEffect(() => {
    if (mounted.current) {
      console.log('setting up identityless trackers')
      window.gtag('set', gtag_config);
      mixpanel.init(mixpanel_id, mixpanel_config);
    }
    return () => {mounted.current = false}
  }, [])

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/welcome' element={<LandingPage />} />
          <Route path='/teams' element={<ForTeams />} />
          <Route path='/medtechs' element={<ForMedTechs />} />
          <Route index element={<LandingPage />} />
          <Route path= '/login' element={<Login />} />
          <Route path= '/register' element={<Register />} />
          <Route path= '/verify' element={<ConfirmEmail />} />
          <Route path= '/waitlist' element={<Waitlist />} />
          <Route path= '/requestpasswordreset' element={<RequestPasswordReset />} />
          <Route path= '/passwordreset/' element={<PasswordReset />} />
          <Route path= '/moreinfo' element={<MoreInfoRedirect />} />
          <Route path= '/share/:share_code' element={<PublicShare />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<AppLayout />}>
              <Route path= '/library' element={<Library />}/>
              <Route path= '/viewer/:attachment_id' element={<Viewer />}/>
              <Route path= '/editor/:attachment_id' element={<Editor />}/>
              <Route path= '/home' element={<Home />}/>
              <Route path= '/messages' element={<Messages />}/>
              <Route path= '/group/:group_id' element={<GroupHome />}/>
              <Route path= '/profile' element={<Profile />}/>
              <Route path= '/about' element={<About />}/>
              <Route path= '/exports/:export_id' element={<ExportDownload />}/>
            </Route>
          </Route>
          <Route element={<ProtectedAdminRoute user={user} setUser={(u) => dispatch(setUser(u))}/>}>
            <Route element={<AppLayout />}>
            <Route path='/admin' element={<Admin />} />
            <Route path='/admin/users' element={<AdminUsers setUser={(u) => dispatch(setUser(u))} />}/>
            <Route path='/admin/users/:user_id' element={<AdminUser setUser={(u) => dispatch(setUser(u))} />}/>
            <Route path='/admin/groups' element={<AdminGroups />}/>
            <Route path='/admin/groups/:group_id' element={<AdminGroup />}/>
            <Route path='/admin/groups/:group_id/members' element={<AdminGroupMembers />}/>
            <Route path='/admin/groups/:group_id/members/:member_id' element={<AdminGroupMember />}/>
            <Route path='/admin/agreements' element={<AdminAgreements />} />
            <Route path='/admin/agreements/:agreement_id' element={<AdminAgreement />} />
            <Route path='/admin/agreements/:agreement_id/versions' element={<AdminAgreementVersions />} />
            <Route path='/admin/agreements/:agreement_id/versions/:version_id' element={<AdminAgreementVersion />} />
            <Route path='/admin/organizations' element={<AdminOrganizations />} />
            <Route path='/admin/organizations/:organization_id' element={<AdminOrganization />} />
            <Route path='/admin/organizations/:organization_id/members' element={<AdminOrganizationUsers />} />
            <Route path='/admin/organizations/:organization_id/members/:member_id' element={<AdminOrganizationUser />} />
            <Route path='/admin/tags' element={<AdminTags />} />
            <Route path='/admin/tags/:tag_id' element={<AdminTag />} />
            <Route path='/admin/metrics' element={<AdminMetrics />} />
            </Route>
          </Route>
          <Route path="*" element={<p><br/><br/><br/>There's nothing here: 404!</p>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
