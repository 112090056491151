import { styled } from "styled-components";
import React, { useState, useEffect, useRef } from 'react';
import { url_api } from '../sources.js';
import { useNavigate, useLocation, Link } from "react-router-dom";
import Button from '../store/components/Button.js';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import mixpanel from 'mixpanel-browser';
import { gtag_id, gtag_config } from "../sources.js";
import Header from "../store/components/Header.js";
import { HeaderBackground } from "../store/pages/LandingPage.js";
import { GlassContainer } from "../store/components/GlassContainer.js";
import MfaInput from "./MfaInput.js";

const Register = () => {

    const share_code = localStorage.getItem('share_code');

    const [userData, setUserData] = useState({share_code: share_code});
    const [successMessage, setSuccessMessage] = useState();
    const [inputIsDisabled, setInputIsDisabled] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [passwordErrors, setPasswordErrors] = useState([]);
    const [emailErrors, setEmailErrors] = useState([]);

    useEffect(() => {
        let pwErrors = validatePassword();
        let emailErrors = validateEmail();

        setPasswordErrors(pwErrors);
        setEmailErrors(emailErrors);

        let submitEnabled = (
            pwErrors.length === 0 &&
            emailErrors.length === 0 &&
            userData.email &&
            userData.first_name &&
            userData.last_name &&
            userData.password
        );
        setSubmitEnabled(submitEnabled);
    }, [userData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const response = await fetch(url_api + '/v1/register/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData)
        });
        console.log(response);

        if (!response.ok) {
            if (response.status === 400) {
                setSuccessMessage("We already have an account with that email address. Please log in or reset your password.");
                setIsLoading(false);
                setSubmitEnabled(false);
            }
            else {
                setSuccessMessage("An error occurred. Please try again later.");
                setIsLoading(false);
                setSubmitEnabled(true);
            }
        }
        else {
            const data = await response.json();
            if (data.error) {
                setSuccessMessage(data.error);
                setIsLoading(false);
                setSubmitEnabled(false);
            } else {
                setSuccessMessage("Success! Please check your email to verify your account.");
                setInputIsDisabled(true);
                setIsLoading(false);
                setSubmitEnabled(false);
                mixpanel.identify(data.id);
                mixpanel.track("User Registered", {
                    "email": userData.email,
                    "name": userData.first_name + " " + userData.last_name,
                    "share_code": userData.share_code,
                });
                mixpanel.people.set({
                    "$email": userData.email,
                    "$name": userData.first_name + " " + userData.last_name,
                    "Signup Date": new Date(),
                    "Via Public Share": userData.share_code ? true : false,
                });
                if (userData.share_code) {
                    recordPublicShareJoin(userData);
                }
            }
        }
    }

    async function recordPublicShareJoin (userData) {
        const formData = new FormData();
        formData.append('EMAIL', userData.email);
        formData.append('LIST', 'PublicShareJoins');
        formData.append('NAME', userData.first_name + ' ' + userData.last_name);

        try {
            const response = await fetch(
              "https://script.google.com/macros/s/AKfycbxMqWXWiHIa9zSy4LJ838bAsO4_toAWu6qtoMeLF9F57TzARoJP2_tHnh-dnYQZm58/exec", 
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded", // Use URL-encoded form data
                },
                body: new URLSearchParams(formData).toString(),
              }
            );
        
            if (response.ok) {
              // Handle success
              console.log("Form submitted successfully!");
            } else {
              // Handle failure
              console.error("Form submission failed.");
            }
          } catch (error) {
            // Handle network errors or exceptions
            console.error("Error submitting the form:", error);
          }
    }

    const validateEmail = () => {
        let emailErrors = [];

        if (userData.email) {
            const emailRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
            if (!emailRegex.test(userData.email)) {
                emailErrors.push('Invalid email address.');
            }
        }

        return emailErrors;
    }

    const validatePassword = () => {
        let pwErrors = [];

        if (userData.password) {
            // check password meets requirements
            if (userData.password.length < 8) {
                pwErrors.push('Password must be at least 8 characters long.');
            }
            if (!userData.password.match(/[A-Z]/)) {
                pwErrors.push('Password must contain at least one uppercase letter.');
            }
            if (!userData.password.match(/[a-z]/)) {
                pwErrors.push('Password must contain at least one lowercase letter.');
            }
            if (!userData.password.match(/[0-9]/)) {
                pwErrors.push('Password must contain at least one number.');
            }
            if (!userData.password.match(/[!@#$%^&\*]/)) {
                pwErrors.push('Password must contain at least one special character.');
            }
        }

        return pwErrors;
    }

    const handleInput = (e) => {
        let attribute = e.target.name;
        let value = e.target.value;
        setUserData({...userData, [attribute]: value});
    }

    return ( 
        <PageContainer>
             <HeaderBackground>
                <img src="/images/store/Landing/landing_bkg.svg" alt="background shapes" />
            </HeaderBackground>
            <Header />
            {/* <div style={{display: 'flex', height: "max(50vh, 300px)", alignSelf: "center"}}> */}
            <div style={{display: 'flex', height: "100%", alignItems: "center"}}>
            <div style={{display: 'flex', minHeight: "50vh", width: "60vw"}}>
            <GlassContainer>
                <Wrap>
                <h4>Welcome!</h4>
                <Explanation>
                    Register for a Free account! Watch unlimited surgical video, interact with Surgeons and other users, and more.
                    Should you choose to, you can upgrade to a Professional account at any time.
                </Explanation>
                <br/>
                <Form style={{width: "50%"}} onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email *</Form.Label>
                        <Form.Control type="text" placeholder="Enter email" onChange={handleInput} disabled={inputIsDisabled} name="email" />
                        <ErrorList>
                        { userData.email ? (
                            <div>
                                {emailErrors.map((error, index) => (
                                    <ErrorItem key={index}>{error}</ErrorItem>
                                ))}
                            </div>
                            ) : (null)
                        }
                        </ErrorList>
                    </Form.Group>
                    <Form.Group controlId="formBasicFirstName">
                        <Form.Label>First Name *</Form.Label>
                        <Form.Control type="text" placeholder="Enter first name" onChange={handleInput} disabled={inputIsDisabled} name="first_name" />
                    </Form.Group><br />
                    <Form.Group controlId="formBasicLastName">
                        <Form.Label>Last Name *</Form.Label>
                        <Form.Control type="text" placeholder="Enter last name" onChange={handleInput} disabled={inputIsDisabled} name="last_name" />
                    </Form.Group><br />
                    {/* <Form.Group controlId="formBasicSpecialty">
                        <Form.Label>Specialty *</Form.Label>
                        <Form.Select placeholder="Enter specialty" onChange={handleInput} disabled={inputIsDisabled} name="specialization">
                            <option value="">-- Select Specialty --</option>
                            <option value="general">General Surgery</option>
                            <option value="orthopedic">Orthopedic Surgery</option>
                            <option value="cardiothoracic">Cardiothoracic Surgery</option>
                            <option value="neuro">Neurosurgery</option>
                            <option value="plastic">Plastic Surgery</option>
                            <option value="vascular">Vascular Surgery</option>
                            <option value="urology">Urological Surgery</option>
                            <option value="pediatric">Pediatric Surgery</option>
                            <option value="colorectal">Colorectal Surgery</option>
                            <option value="opthalmology">Opthalmologic Surgery</option>
                            <option value="otolaryngologic">Otolaryngologic Surgery</option>
                            <option value="gynecologic">Gynecologic Surgery</option>
                            <option value="oral_maxillofacial">Oral and Maxillofacial Surgery</option>
                            <option value="transplant">Transplant Surgery</option>
                            <option value="bariatric">Bariatric Surgery</option>
                            <option value="trauma">Trauma Surgery</option>
                            <option value="hand">Hand Surgery</option>
                            <option value="oncology">Oncological Surgery</option>
                            <option value="thoracic">Thoracic Surgery</option>
                            <option value="hepatobiliary">Hepatobiliary Surgery</option>
                        </Form.Select>
                    </Form.Group><br />
                    <Form.Group controlId="formBasicOrganization">
                        <Form.Label>Organization *</Form.Label>
                        <Form.Control type="text" placeholder="Enter organization" onChange={handleInput} disabled={inputIsDisabled} name="company" />
                    </Form.Group><br /> */}
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password *</Form.Label>
                        <Form.Control type="password" placeholder="Enter password" onChange={handleInput} disabled={inputIsDisabled} name="password" />
                        <ErrorList>
                        { userData.password ? (
                            <div>
                                {passwordErrors.map((error, index) => (
                                    <ErrorItem key={index}>{error}</ErrorItem>
                                ))}
                            </div>
                            ) : (null)
                        }
                        </ErrorList>
                    </Form.Group>
                    <Form.Text className="text-muted">
                        <p>{successMessage}</p>
                    </Form.Text>
                    { (isLoading) ? (<Spinner/>) : (<Button text="Register" disabled={!submitEnabled} color="#3D85BB" textColor="white" type="submit"/>)}
                </Form>
                </Wrap>
            </GlassContainer>
            </div>
            </div>
        </PageContainer>
     );
}

const PageContainer = styled.section`
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
`;

const Explanation = styled.span`
    text-align: left;
    width: 60%;
    font-size: 0.8em;
`;

const Wrap = styled.div`
    background-color: rgba(250, 250, 250, 0.6);
    position: relative;
    height: 100%;
    min-height: 290px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    border-radius: 10px;
    padding: 15px 0px;

    a {
        color: #007bff;
        font-size: 13px;
        font-style: underline;
    }

    h4 {
        color: #3D85BB;
    }
`;

const ErrorList = styled.ul`
    list-style-type: none;
    padding-top: 5px;
    font-size: 0.8rem;
`;

const ErrorItem = styled.li`
    list-style-type: '❌ ';
    color: red;
    width: 100%;
`;

export default Register;
