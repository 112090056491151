import { useParams, Navigate, useLocation, Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getAttachmentPublicShareByCode, activateAttachmentPublicShare } from '../services/api/attachment_public_shares.js';
import { styled } from "styled-components";
import Header from "../store/components/Header.js";
import { Spinner } from 'react-bootstrap';
import { HeaderBackground } from "../store/pages/LandingPage.js";
import { GlassContainer } from "../store/components/GlassContainer.js";


const PublicShare = () => {
    /* The landing page for public share links.
    *  This page is shown when a user clicks on a public share link and is not logged in.
    *  The user is shown a preview of the file and a prompt to log in or create an account.
    */
    const { share_code } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.value);

    const [ share, setShare ] = useState(null);
    const [ next, setNext ] = useState(null);
    const [ shareError, setShareError ] = useState(false);

    useEffect(() => {
        getAttachmentPublicShareByCode(share_code)
        .then(response => {
            setShare(response);
            setNext('/viewer/' + response.attachment_id);
        })
        .catch(error => {
            setShareError(true);
            setNext(location.pathname)
        });
    }, []);

    return (
        <PageContainer>
            <HeaderBackground>
                <img src="/images/store/Landing/landing_bkg.svg" alt="background shapes" />
            </HeaderBackground>
            <Header />
            <div style={{display: 'flex', height: "100%", alignItems: "center"}}>
            <div style={{display: 'flex', minHeight: "50vh", width: "60vw"}}>
            <GlassContainer>
                <Wrap>
                {!share && !shareError ? (
                    <div>
                        <Spinner animation="border" variant="primary" /><br />
                        Loading share...
                    </div>
                ): null}
                {!user ? (
                    <div>
                        <h4>Log in to view this share</h4>
                        <br />
                        <RegisterButton onClick={() => {
                            navigate('/login', {state: {from: next}});
                        }}>Log In</RegisterButton><br /><br />
                        <RegisterButton onClick={() => {
                            localStorage.setItem('share_code', share_code);
                            navigate('/register');
                        }}>Register for Free</RegisterButton>
                    </div>
                ) : null}
                {(shareError && user) ? (
                    <div>
                        The video is no longer publicly available.<br />
                        <Link to="/home">Go to my Dashboard</Link>
                    </div>
                ) : null}
                {(user && share) ? (
                    <Navigate to={next} />
                ) : null}
                </Wrap>
            </GlassContainer>
            </div>
            </div>
        </PageContainer>
    );
};

export default PublicShare;

const RegisterButton = styled.span`
    text-align: center;
    font-size: 1em;
    font-style: none;
    cursor: pointer;
    border-radius: 30px;
    padding: 10px 20px;
    background-color: #3D85BB;
    color: white;
`;

const PageContainer = styled.section`
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
`;

const Wrap = styled.div`
    background-color: rgba(250, 250, 250, 0.6);
    position: relative;
    height: 100%;
    min-height: 290px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    border-radius: 10px;

    a {
        color: #007bff;
        font-size: 13px;
        font-style: underline;
    }

    h4 {
        color: #3D85BB;
    }
`;
