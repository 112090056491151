import React from "react";
import { styled } from "styled-components";
import { useState, useEffect, useRef } from "react";
import { getGroup, getGroupMembers, getSharedAttachment, getSharedAttachments, sendMessage, getGroupMessages, deleteMessage } from "../services/api/groups.js";
import { createShare, deleteShare, getAttachments, getShares } from "../services/api/attachments.js";
import Button from 'react-bootstrap/Button';
import { dateFromTimestamp, ageFromTimestamp, messageRecencyFromTimestamp, timeFromTimestamp, hmsTextFromSeconds} from "../services/util/metadata.js";
import { COLORS } from "../values/colors.js";
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import UserIcon from "./UserIcon.js";
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import CloseButton from 'react-bootstrap/CloseButton';
import { Link } from "react-router-dom";
import CasePHIModal from "./CasePHIModal.js";
import { getDM } from "../services/api/dms.js";
import { Placeholder } from "react-bootstrap";
import ConfirmationModal from "./ConfirmationModal.js";

const AttachmentSelector = ({group_id, selectorIsOpen, setSelectorIsOpen, attachmentsToSend, setAttachmentsToSend, showPHIModal, setShowPHIModal,
currentSelection, setCurrentSelection}) => {

    const [attachments, setAttachments] = useState([]);
    const [group, setGroup] = useState(null);
    const [sharedAttachments, setSharedAttachments] = useState([]);
    const user = useSelector(state => state.user.value);

    useEffect(() => {

        setAttachmentsToSend([]);

        getAttachments().then(items => {
            items = items.filter((item) => item.case.owner.id === user.id);
            items = items.filter((item) => item.status > 2);
            setAttachments(items);
        })

        getSharedAttachments(group_id).then(items => {
            setSharedAttachments(items);
        })

        getGroup(group_id).then(result => {
            if (result===null || result===undefined)
                getDM(group_id).then(result => {
                    setGroup(result);
                })
            else
                setGroup(result);
        })

    }, [showPHIModal, group_id])

    function determineShareState(attachment) {
        //already shared
        if (sharedAttachments.some(share => share.attachment_id === attachment.id))
            return [false, 'This video has already been shared with these recipients.'];
        //attestations not complete
        if (attachment.contains_phi === null || attachment.with_patient_consent === null)
            return [true, 'This video requires PHI attestations before it can be shared; click to provide.'];
        //attestations complete
        if (group.allow_phi === false && attachment.contains_phi === true )
            return [false, 'This video cannot be shared in this message based on the PHI attestations previously provided. If you believe this is in error, revisit the sharing settings for this video.'];
        if (group.allow_phi === true && group.require_patient_consent === true && (attachment.contains_phi === true && attachment.with_patient_consent === false))
            return [false, 'This video cannot be shared in this message based on the PHI attestations previously provided. If you believe this is in error, revisit the sharing settings for this video.'];
        return [true, 'This video is ready to be shared!'];

    }

    return (
        <>
        {selectorIsOpen &&
        <AttachmentSelectorContainer>
        <div className='instructions'>
            <span>Choose videos to share:</span>
            <CloseButton onClick={() => setSelectorIsOpen(false)}/>
        </div>
        <AttachmentsContainer>
            {attachments.map((attachment, idx) => {
                let isSelected = attachmentsToSend.some(item => item.id === attachment.id);
                let shareState = determineShareState(attachment);
                return (
                    <AttachmentContainer key={idx}>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 100, hide: 100 }}
                            overlay={shareState[0] ? <></> : <Tooltip>{shareState[1]}</Tooltip>}
                        >
                        <ThumbnailContainer key={idx}>
                            <Thumbnail 
                                isSelected={isSelected}
                                shareState={shareState[0]}
                                src={attachment.thumbnail.download_link} 
                                width='100' 
                                onClick={() => {
                                    setCurrentSelection(attachment)
                                    if (shareState[0] === false)
                                        return;
                                    if (isSelected) {
                                        setAttachmentsToSend(attachmentsToSend.filter((item) => item.id !== attachment.id));
                                        return;
                                    }
                                    if (attachment.contains_phi === null || attachment.with_patient_consent === null) {
                                        setShowPHIModal(true);
                                        return;
                                    }
                                    setAttachmentsToSend([...attachmentsToSend, attachment]);
                                }}
                            />
                        </ThumbnailContainer>
                        </OverlayTrigger>
                        <span className='description'>{attachment?.subtitle ? attachment.subtitle : attachment.case.case_name}</span>
                    </AttachmentContainer>
                    )
                })}
        </AttachmentsContainer>
        </AttachmentSelectorContainer>
        }
        </>
    )
}

const AttachmentSelectorContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    overflow: hidden;

    .instructions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0px 0px 5px 10px;
        font-weight: 600;
        flex-grow: 0;
    }
`;

const AttachmentsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 180px);
    grid-gap: 10px;
    flex-direction: row;
    background-color: white;
    overflow: scroll;
    gap: 10px;
    padding-bottom: 20px;
    padding-top: 10px;
    flex-grow: 0;
    height: 325px;
    width: 100%;

    mask-image: linear-gradient(to top, transparent 0%, white 10px, white calc(100% - 10px), transparent 100%);
    -webkit-mask-image: linear-gradient(to top, transparent 0%, white 10px, white calc(100% - 10px), transparent 100%); /* For Safari */

    `;

const AttachmentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex: 0 1 200px;

    .description {
        font-size: 14px;
        font-weight: 600;
        padding: 5px 0px;
        width: 100%;
        height: 40px;
    }
    `;

const UserIconsContainer = ({members, group_id}) => {

    const [group, setGroup] = useState({});
    const user = useSelector(state => state.user.value);

    useEffect(() => {
        getGroup(group_id).then(group => {
            setGroup(group);
        })
    }
    , [group_id])

    return (
        <MemberNamesContainer>
            {group &&
            <>
            <Link to={`/group/${group_id}`}>
                <img 
                    onLoad={(e) => e.target.style.display='inline-block'}
                    src={group.avatar_url} 
                    width='35' 
                    onError={(e) => e.target.style.display='none'}
                    />
            </Link>
            <Link to={`/group/${group_id}`}>
                <h4>{group.name}</h4>
            </Link>
            </>
            }
            {!group && members && members.map((member, idx) => {
                if (member.is_active && member.user.id !== user.id) {
                    let displayText = '';
                    if (member.user.first_name && member.user.last_name)
                        displayText = member.user.first_name + ' ' + member.user.last_name;
                    else
                        displayText = member.user.email;
                    return (
                        <OverlayTrigger
                            key={idx}
                            placement="bottom-start"
                            delay={{ show: 100, hide: 100 }}
                            overlay={<Tooltip><MemberName>{displayText}</MemberName></Tooltip>}
                        >
                            <span><UserIcon user={member.user} size={35}/></span>
                        </OverlayTrigger>
                    );
                }
            })}
        </MemberNamesContainer>
    )
}

const AttachmentShareMessage = ({group_id, attachment_id, message_id}) => {

    const [attachmentShare, setAttachmentShare] = useState(null);
    const user = useSelector(state => state.user.value);

    useEffect(() => {
        setTimeout(() => {
            getSharedAttachment(group_id, attachment_id).then(item => {
                if (item) {
                    setAttachmentShare(item);
                } else {
                    setAttachmentShare('unshared');
                }

            })
        }, 100)
    }
    , [attachment_id])

    // message sent but share not created yet
    if (attachmentShare === null || message_id === null) {
        return (
            <VideoShareContainer>
                <ThumbnailContainer>
                    <Placeholder as='p' animation='wave'>
                        <Placeholder xs={12} style={{width: '180px', height: '100px'}}/>
                    </Placeholder>
                </ThumbnailContainer>
                <MetadataContainer>
                    <span>Loading...</span>
                </MetadataContainer>
            </VideoShareContainer>
        )
    }

    // message sent, but maybe unshared
    return (
        <>
        {(attachmentShare!=='unshared') ? 
        (<VideoShareContainer key={attachmentShare.attachment.id}>
            <Link to={`/viewer/${attachmentShare.attachment.id}`}>
                <ThumbnailContainer>
                    <Thumbnail src= {attachmentShare.attachment.thumbnail.download_link} />
                    <PlaySymbol>
                        {`\u23F5`}
                    </PlaySymbol>
                </ThumbnailContainer>
            </Link>
            <MetadataContainer>
            <div className='title'>{attachmentShare.attachment?.subtitle ? attachmentShare.attachment.subtitle : attachmentShare.attachment.case.case_name} • {hmsTextFromSeconds(attachmentShare.attachment.user_metadata.duration)}</div>
            <div className='description'>{attachmentShare.attachment.case.description}</div>
            {/* <div className='controls'>
            {(user.id === attachmentShare.attachment.case.owner_id) &&
            <Button 
                variant="link" 
                size='sm'
                onClick={() => {deleteShare(attachmentShare.attachment.id, group_id); setAttachmentShare(null);}}
                >
                Delete
            </Button>}
            </div> */}
            </MetadataContainer>
        </VideoShareContainer>)
        :
        (<NoShare>Shared a video that is no longer available.</NoShare>)
    }</>
    )
}

const VideoShareContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    height: 115px;
    /* width: 100%; */
    overflow: hidden;
    `;

const NoShare = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    color: #999;
    font-style: italic;
    height: 35px;
    width: 100%;
    `;

const ThumbnailContainer = styled.div`
    position: relative;
    display: inline-block;
    transition: opacity 100ms ease-in-out;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;

    &:hover {
        opacity: 0.8;
        div {opacity: 0.7;}
    }
    `;

const Thumbnail = styled.img`
    width: 180px;
    height: auto;
    border-radius: 5px;

    ${props => props.isSelected && `
        transform: scale(.96);
        filter: brightness(80%);
    `}
    ${props => props.shareState === false && `
        opacity: .2;
    `}
`;

const PlaySymbol = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 200ms ease-in-out;
    opacity: 0;
    font-size: 50px;
    color: white;
    z-index: 1;
`;

const MetadataContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 10px;
    /* width: 100%; */

    .title{
        font-weight: 600;
        text-align: left;
    }

    .description {
        /* display: inline-flex; */
        flex-direction: column;
        font-weight: 400;
        /* width: 100%; */
        -webkit-line-clamp: 3;
        text-align: left;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        padding: 2px 0px;
    }

    /* .controls {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        margin: 0;
        padding: 0;
    } */
    `;

const ChatArea = ({group_id, no_logo}) => {

    const [members, setMembers] = useState([]);
    const [attachmentsToSend, setAttachmentsToSend] = useState([]);
    const [selectorIsOpen, setSelectorIsOpen] = useState(false);
    const [displayedMessages, setDisplayedMessages] = useState([]);
    const [messageText, setMessageText] = useState('');
    const [userDict, setUserDict] = useState({});
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [loadMessage, setLoadMessage] = useState('');
    const [showPHIModal, setShowPHIModal] = useState(false);
    const [currentSelection, setCurrentSelection] = useState(null);
    const chatRef = useRef(null);
    const chatHeight = useRef(null);
    const messages = useRef(null);
    const user = useSelector(state => state.user.value);
    const [ showDeleteModal, setShowDeleteModal ] = useState(false);
    const [ deleteItem, setDeleteItem ] = useState(null);
    const [ deleteText, setDeleteText ] = useState('');
    const [ deleteTitle, setDeleteTitle ] = useState('');

    useEffect(() => {

        const messageFetchController = new AbortController();

        getGroupMembers(group_id).then(items => {
            setMembers(items);
            return items;
        })
        .then((memberlist) => {
            let tempDict = {};
            memberlist.map(member => {
                tempDict[member.user_id] = member.user.first_name + ' ' + member.user.last_name;
            })
            setUserDict(tempDict);
        })

        getGroupMessages(group_id)
        .then(results => {
            messages.current = results['messages'];
            setDisplayedMessages(results['messages'].reverse());
            setLoadMessage('')
            if (messages.current.length == 0) {
                setLoadMessage('Be the first one to send a message to the group!')
            } else
            if (chatRef.current.scrollHeight > chatRef.current.clientHeight) {
                setIsLoadingMore(() => true);
            }
        })

        setTimeout(() => {chatRef.current.scrollTop=10000000}, 150);
        console.log('adding scroll listener')
        chatRef.current.addEventListener('scroll', (event) => {
            if (event.target.scrollTop === 0) {
                console.log('fetching messages for group ' + group_id)
                getGroupMessages(group_id, {'last_timestamp': messages.current[0].timestamp}).then(results => {
                    messages.current = combineMessageLists(messages.current, results['messages'])
                    if (!results['last_timestamp']) {
                        setIsLoadingMore(() => false);
                    } else {
                        setIsLoadingMore(() => true);
                    }
                    setDisplayedMessages(() => messages.current);
                })
            }
        }, {signal: messageFetchController.signal})

        return () => {messageFetchController.abort(); console.log('removing scroll listener')}
    }, [group_id])

    //useEffect hook to get messages every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            console.log('auto fetching messages for group ' + group_id)
            getGroupMessages(group_id,  {'first_timestamp': messages.current[messages.current.length -1]?.timestamp}).then(results => {
                if (results){
                    messages.current = combineMessageLists(messages.current, results['messages'])
                    setDisplayedMessages(() => messages.current);
                }
            })
        }, 15000);
        return () => clearInterval(interval);
    }, [group_id])

    //useEffect hook to manage the chat scroll positiom when new messages are added
    useEffect(() => {
        try{
            //if the chat is scrolled to the bottom, scroll to the bottom after new messages are added
            // console.log(chatRef.current.scrollTop, chatHeight.current - chatRef.current.clientHeight)
            if ((chatHeight.current - chatRef.current.clientHeight) - chatRef.current.scrollTop < 50)
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
            //else, keep the scroll position the same
            else if (chatRef.current.scrollTop === 0)
                chatRef.current.scrollTop = chatRef.current.scrollHeight - chatHeight.current;
            //update the chat height
            chatHeight.current = chatRef.current.scrollHeight;
            
        } catch (error) {
            console.log(error)
        }
    }, [displayedMessages])

    //this function combines two message lists with no duplicate items, and 
    //returns the combined list sorted by message.timestamp
    function combineMessageLists(List1, List2) {
        let combinedList = List2;

        //merge the lists without duplicates
        List1.map((item) => {
            if (!List2.find((element) => element.message_id === item.message_id)) {
                combinedList.push(item);
            }
        })
        
        //sort the combined list by timestamp
        return combinedList.sort((a, b) => {
            return new Date(a.timestamp) - new Date(b.timestamp);
        })
    }

    function createLocalMessage(text) {
        return {
            id: null,
            text: text,
            user_id: user.id,
            timestamp: new Date().toISOString().split("Z")[0]
        }
    }

    function sendTextAndVideo() {
        if (messageText.trim() !== '') {
            setDisplayedMessages((displayedMessages) => [...displayedMessages, createLocalMessage(messageText)])
            sendMessage(group_id, {text: messageText});
            setMessageText('');
            mixpanel.track('Sent Message', {
                'Source': 'Messages Page',
                'Group ID': group_id,
                'Message Length': messageText.length
            })
        }
        if (attachmentsToSend.length > 0) {
            Promise.all(attachmentsToSend.map(attachment => {
                console.log('sharing attachment ' + attachment.id + ' with group ' + group_id)
                setDisplayedMessages((displayedMessages) => [...displayedMessages, createLocalMessage('ATTACHMENT_SHARE:ATTACHMENT_ID:' + attachment.id)])
                return createShare(attachment.id, { "group_id" : group_id });
            }))
            .then(() => {
                attachmentsToSend.map(attachment => {
                    mixpanel.track('Shared Video', 
                    {
                        'Source': 'Messages Page', 
                        'Group ID': group_id, 
                        'Attachment ID': attachment.id
                    }
                )})
                setAttachmentsToSend([]);
            })
        }
    }

    function isMessageShare(message) {
        return message.text.split(':')[0] === 'ATTACHMENT_SHARE';
    }

    function handleDeleteMessage(message) {
        setDeleteItem(message);
        setDeleteTitle(isMessageShare(message) ? 'Unshare video?' : 'Delete message?');
        setDeleteText(isMessageShare(message) ? (
            'You are about to unshare a video. The recipients will no longer be able to view this video until you reshare the item. Are you sure?'
        ) : (
            'Are you sure you want to delete this message?'
        ));
        setShowDeleteModal(true);
    }

    function hideModal() {
        setDeleteItem(null);
        setDeleteTitle('');
        setDeleteText('');
        setShowDeleteModal(false);
    }

    function doDeleteMessage(message) {
        //delete message from the server
        //deleteMessage(group_id, message_id);
        //delete message from the local state
        if (isMessageShare(message)) {
            deleteShare(message.text.split('ATTACHMENT_ID:')[1], group_id);
        }

        Promise.all([deleteMessage(group_id, message.message_id)]).then(() => {
            messages.current = messages.current.filter(item => item.message_id !== message.message_id);
            setDisplayedMessages(messages.current);
        })
    }

    return (
        <ChatContainer>
                {members && !no_logo &&
                    <UserIconsContainer members={members} group_id={group_id}/>
                }
            <MessageDisplay ref={chatRef}>
                <p align='center'>{loadMessage}</p>
                <div className='spinnerContainer'>
                {isLoadingMore ? <Spinner variant='secondary'/> : <>•</>}
                </div>
                {members && displayedMessages?.length>0 && displayedMessages.map((message, idx) => {
                    let isRecent = (idx == 0 || (dateFromTimestamp(displayedMessages[idx-1]?.timestamp) !== dateFromTimestamp(message.timestamp)));
                    return <MessageContainer key={idx} isRecent={isRecent}>
                        <div className='icon'><UserIcon user={members.find(member => member.user_id === message.user_id)?.user} size={35}/></div>
                        { isRecent && <MessageTimestamp>{messageRecencyFromTimestamp(message.timestamp)}</MessageTimestamp>} 
                        <MessageContent>
                            <MessageByline>
                                <span>{userDict[message.user_id]}</span> 
                                <span className='time'>• {timeFromTimestamp(message.timestamp)}</span>
                                { message.user_id === user.id && message.message_id !== undefined &&
                                    <MessageDeleteButton class="deleteButton" key={message.message_id} onClick={() => {handleDeleteMessage(message)}}>
                                        delete
                                    </MessageDeleteButton>
                                }
                            </MessageByline>
                            {message.text.split(':')[0]=== 'ATTACHMENT_SHARE' ?
                            <AttachmentShareMessage group_id={group_id} attachment_id={message.text.split('ATTACHMENT_ID:')[1]} message_id={message.id}/> :
                            <MessageText>{message.text}</MessageText>}
                        </MessageContent>
                    </MessageContainer>
                })}
            </MessageDisplay>
            <InputArea>
            <AttachmentSelector
                group_id={group_id}
                selectorIsOpen={selectorIsOpen}
                setSelectorIsOpen={setSelectorIsOpen}
                attachmentsToSend={attachmentsToSend}
                setAttachmentsToSend={setAttachmentsToSend}
                showPHIModal={showPHIModal}
                setShowPHIModal={setShowPHIModal}
                currentSelection={currentSelection}
                setCurrentSelection={setCurrentSelection}
            />
            <MessageBox 
                type="text" 
                value={messageText} 
                placeholder='Enter a message...'
                onChange={(e) => setMessageText(e.target.value)} 
                onKeyUp={(e) => {
                    e.preventDefault();
                    if (e.key === 'Enter'&& !e.shiftKey) {
                        sendTextAndVideo();
                    }
                }} />
            <MessageControls>
                <AttachmentList>
                    {attachmentsToSend.length > 0 &&
                            attachmentsToSend.map((attachment, idx) => {
                                return (
                                    <AttachmentItem key={idx}>{`\u23F5 ` + attachment?.subtitle ? attachment.subtitle : attachment.case.case_name}</AttachmentItem>
                                )
                            })
                        }
                </AttachmentList>
                <ButtonContainer>
                    <Button 
                        size="sm"
                        onClick={() => {setSelectorIsOpen(!selectorIsOpen);}}
                        >
                        +
                    </Button>
                    <Button 
                        size="sm"
                        onClick={sendTextAndVideo}
                        >
                        Send
                    </Button>
                </ButtonContainer>
            </MessageControls>
        </InputArea>
        <CasePHIModal
            show={showPHIModal}
            onHide={() => setShowPHIModal(false)}
            onSave={() => {}}
            user={user}
            attachment={currentSelection}
        />

        <ConfirmationModal
            show={showDeleteModal}
            title={deleteTitle}
            message={deleteText}
            onConfirm={() => {doDeleteMessage(deleteItem)}}
            onHide={() => {hideModal()}} />

    </ChatContainer>
    )
}

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    min-width: 300px;
    overflow: hidden;
`;

const MessageDeleteButton = styled.div`
    color: gray;
    display: none;
    padding: 0px 10px;
    font-size: 12px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const MessageDisplay = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 10px;
    padding: 0px 10px;
    align-items: center;
    justify-content: flex-start;

    .spinnerContainer {
        min-height: 50px;
        color: #999;
    }
`;

const MessageContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
    padding-top: 10px;
    width: 100%;
    height: min-content;
    align-items: flex-start;
    margin: 0px;

    ${props => props.isRecent && `
        border-top: 1px solid #f0f0f0;
    `}
    
    .icon {
        margin-top: 10px;
    }

    &:hover ${MessageDeleteButton} {
        display: inline-flex;
    }
`;

const MessageContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 7px 0px;
`;

const MessageByline = styled.div`
    display: flex;
    flex-direction: row;
    color: black;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    padding: 0 10px;
    background-color: white;
    height: 20px;
    align-self: flex-start;
    margin: 0px;

    .time {
        color: #999;
        font-weight: 400;
        margin-left: 5px;
        font-size: 14px;
        align-items: center;
    }
`;

const MessageTimestamp = styled.div`
    position: absolute;
    display: flex;
    right: 50%;
    bottom: 100%;
    color: #999;
    font-size: 14px;
    font-weight: 400;
    background-color: white;
    padding: 0 20px;
    transform: translate(50%, 50%);
`;

const MessageText = styled.div`
    display: inline-flex;
    white-space: pre-wrap;
    text-align: left;
    padding: 0px 10px;
    margin: 0px;
`;

const InputArea = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
    height: auto;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;

    textarea {
        border: 0px solid white;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.04);
        padding: 5px 10px;
        &:focus {
            outline: none;
        }
        margin-bottom: 5px;
    }
`;

const MessageBox = styled.textarea`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3px 5px;
    height: 80px;
    resize: none;
    flex-shrink: 0;
`;

const MemberName = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: white;
    border-radius: 10px;
    padding: 2px 4px 2px 4px;
`;

const MemberNamesContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    gap: 7px;
    height: auto;

    a {
        text-decoration: none;
        color: black;
        text-align: left;
        &:hover {
            color: #007bff;
        }
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

    button {
        font-weight: 600;
    }
`;

const AttachmentList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    height: auto;
    width: 100%;
    padding: 5px 0px;
`;  

const MessageControls = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    height: auto;
    width: 100%;
    padding: 5px 0px;
    flex-grow: 0;
`;

const AttachmentItem = styled.div`
    display: flex;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 7px;
    border-radius: 15px;
    background-color: ${COLORS.orchid_teal};
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
`;

export default ChatArea;