import { useState, useEffect } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import styled from 'styled-components';
import { dateFromTimestamp } from '../services/util/metadata.js';
import { Link, useNavigate } from 'react-router-dom';
import { COLORS } from '../values/colors.js';
import { getThumbnailUrl } from '../services/api/attachments.js';
import { hmsTextFromSeconds } from '../services/util/metadata.js';
import { Placeholder } from 'react-bootstrap';


const VideoListItemDummy = () => {
    return(
        <ItemContainer
            className="video-list-item"
        >
            <ItemHeader>
                <ThumbnailContainer>
                    <Placeholder animation='wave'>
                        <Placeholder as={Thumbnail} style={{width: '180px', height: '100px'}}/>
                    </Placeholder>
                </ThumbnailContainer>
                <VideoByline>
                    <Placeholder xs={11} style={{height: '38px'}}/>
                    <Spacer/>
                    <Placeholder xs={8} style={{height: '20px'}}/>
                    <Spacer/>
                    <Placeholder xs={4} style={{height: '16px'}}/>
                </VideoByline>
            </ItemHeader>
        </ItemContainer>
    );
}

const Spacer = styled.div`
    width: 100%;
    height: 10px;
`;


const VideoListItem = ({ video, handleVideoSelect, selectedVideo }) => {

    const [videoThumbnail, setVideoThumbnail] = useState(null);
    const navigate = useNavigate();

    const percentWatched = video.user_view / video.user_metadata.duration;

    useEffect(() => {
        // setVideoThumbnail(video.thumbnail.download_link);
        setVideoThumbnail(getThumbnailUrl(video));
    }, [video]);

    return(
        <ItemContainer 
            className="video-list-item" 
            onClick={(e) => {handleVideoSelect(video)}}
        >
            <ItemHeader>
                <ThumbnailContainer
                    onClick={(e) => {if (video.status===3) {navigate(`/viewer/${video.id}`);} else {e.stopPropagation();}}}
                >    
                    <Thumbnail 
                        src={videoThumbnail} 
                        alt={video.case.case_name} 
                    />
                    {video.status != 3 ? (
                    <StatusContainer>
                        {video.status === 1 ? (
                            <span>Uploading</span>
                        ) : (
                            <span>Processing</span>
                        )}
                    </StatusContainer>
                    ) : (
                    <PlaySymbol>
                        {`\u23F5`}
                    </PlaySymbol>
                    )} 
                    {percentWatched > 0 &&
                        <AlreadyWatchedBar 
                            style={{width: `${percentWatched*100}%`}}
                        />
                    }
                </ThumbnailContainer>
                <VideoByline>
                    <span>
                        <h4 onClick={(e) => {if (video.status===3) {navigate(`/viewer/${video.id}`);} else {e.stopPropagation();}}}>
                            {video?.subtitle ? video.subtitle : video.case.case_name}
                        </h4>
                    </span>
                    <span>
                        {
                            video.case.owner.first_name + ' ' + 
                            video.case.owner.last_name + '  \u2022  ' + 
                            dateFromTimestamp(video.created_on)
                        }
                    </span>
                    <span>
                        <span>{hmsTextFromSeconds(video?.user_metadata?.duration, 'hm')}</span>
                        <span>
                            {(video.markers?.length > 0) && 
                                (`  \u2022  ` + video.markers?.length + 
                                ` chapter` + (video.markers?.length>1 ? `s` : ``))
                            }
                        </span>
                    </span>
                </VideoByline>
                <ExpandSymbol 
                    isSelected={selectedVideo === video}
                >
                     {`\u2039`}
                </ExpandSymbol>
            </ItemHeader>
            <TagsArea>   
            {video.group?.name && (
                <Link to={`/group/${video.group.id}`}>
                    <GroupAttribution onClick={(e)=>e.stopPropagation()}>
                        <span>{video.group.name}</span>
                    </GroupAttribution>
                </Link>
            )}
            </TagsArea>    
            <Collapse in={selectedVideo === video}>
                <DetailsContainer className="video-list-item-details">
                    <span>{video.case.description}</span>
                </DetailsContainer>
            </Collapse>
        </ItemContainer>
    );
;}

const ItemContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 5px 0;
    color: #333;

    &:hover {
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
        transform: scale(1.01);
    }
 `;

const ItemHeader = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    height: 120px;
    margin-bottom: 5px;
    min-width: 270px;

    h4 {
        &:hover {
            color: ${COLORS.orchid_indigo};
        }
    }
`;

const ThumbnailContainer = styled.div`
    position: relative;
    display: inline-flex;
    /* flex: 0 0 213; */
    border-radius: 5px;
    overflow: hidden;
    height: 120px;
    min-width: 213.33px;
    align-items: center;
    justify-content: center;
    
    &:hover {
        opacity: 0.8;
        div {opacity: 0.7;}
    }
`;

const Thumbnail = styled.img`
    height: 120px;
    width: auto;
    border-radius: 5px;
`;

const PlaySymbol = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 200ms ease-in-out;
    opacity: 0;
    font-size: 50px;
    color: white;
    z-index: 1;
`;

const TagsArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const GroupAttribution = styled.div`
    display: inline-block;
    font-size: 13px;
    color: white;
    border-radius: 10px;
    padding: 1px 5px;
    z-index: 1;
    background-color: ${COLORS.orchid_indigo};
`;

const StatusContainer = styled.div`
    display: inline-block;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    font-weight: 600;
    color: white;
    border-radius: 10px;
    padding: 1px 5px;
    z-index: 2;
    background-color: ${COLORS.orchid_dark};
`;

const VideoByline = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 5px;
    font-size: 15px;
    color: #666;
    margin-left: 10px;
    width: 100%;
    text-align: left;
    overflow: hidden;

    h4 {
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 3px;
    }
`;

const ExpandSymbol = styled.div`
    align-self: center;
    justify-self: flex-end;
    font-size: 40px;
    color: #666;
    cursor: pointer;
    rotate: 90deg;
    transition: all 150ms ease-in-out;

    ${(props) => props.isSelected && `
        transform: scaleX(-1);
    `}
`;

const DetailsContainer = styled.div`
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 15px;
    width: 100%;
`;

const AlreadyWatchedBar = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: ${COLORS.orchid_dark};
`;

const VideoList = ({ attachments, title, emptyMessage, isLoading }) => {

    const [displayedVideos, setDisplayedVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [searchTerms, setSearchTerms] = useState('');

    useEffect(() => {
        setDisplayedVideos(attachments);
    }, [attachments]);

    const handleVideoSelect = (video) => {
        if (selectedVideo === video) {
            setSelectedVideo(null);
            return;
        }
        setSelectedVideo(video);
    }

    const handleSearch = (e) => {
        setSearchTerms(e.target.value);
        if (e.target.value === '') {
            setDisplayedVideos(attachments);
        } else {
            const words = e.target.value.split(' ');
            const filteredVideos = attachments.filter((video) => {
                let match = Array(words.length).fill(false);
                words.forEach((word, idx) => {
                    if (video.case.case_name.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video?.subtitle?.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.case.description.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.case.owner.first_name.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.case.owner.last_name.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.created_on.toLowerCase().includes(word.toLowerCase())) {
                        match[idx] = true;
                    }
                    if (video.markers.length > 0) {
                        video.markers.forEach((marker) => {
                            if (marker.name.toLowerCase().includes(word.toLowerCase())) {
                                match[idx] = true;
                            }
                            if (marker.description.toLowerCase().includes(word.toLowerCase())) {
                                match[idx] = true;
                            }
                        });
                    }
                });
                return match.every(value => value === true);
            });
            setDisplayedVideos(filteredVideos);
        }
    }

    return ( 
        <VideoListContainer>
            <VideoListHeader>
                <h3>{title}</h3>
                <VideoListCount>{displayedVideos.length} videos</VideoListCount>
            </VideoListHeader>
            <SearchBar>
                <input 
                    type="text" 
                    placeholder="Search videos..." 
                    value={searchTerms}
                    onChange={handleSearch}
                />
            </SearchBar>
            <VideoItemList>
                {isLoading ? 
                    [1,2,3].map((index) => (
                        <VideoListItemDummy key={index}/>
                    )) :
                    displayedVideos.map((video, index) => (
                        <VideoListItem 
                            key={index} 
                            video={video} 
                            handleVideoSelect={handleVideoSelect}
                            selectedVideo={selectedVideo}
                        />
                    ))
                }
                {attachments.length === 0 && 
                <NoVideosMessage>
                    {emptyMessage}
                </NoVideosMessage>}
            </VideoItemList>
        </VideoListContainer>
     );
}

const VideoListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: visible;
`;

const SearchBar = styled.div`
    display: flex;
    flex-direction: column;
    align-content: stretch;
    justify-content: stretch;
    width: inherit;
    margin: 10px 0;
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    /* margin-left: 10px;
    margin-right: auto; */

    input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 15px;
        color: #333;
    }
`;

const VideoListHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    h3 {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 600;
    }
`;

const VideoListCount = styled.div`
    margin-left: 10px;
    font-size: 15px;
    color: #666;
`;

const VideoItemList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 5px 10px 10px 10px;
    /* background-image: URL('/images/logo_nobkg_a70.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
`;

const NoVideosMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;
    font-size: 15px;
    width: 100%;
    height: 100%;
`;

export default VideoList;