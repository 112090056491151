import Modal from 'react-bootstrap/Modal';
import { useState, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Form, OverlayTrigger, Tooltip, CloseButton } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { getGroupMembers, getGroups } from "../services/api/groups.js"
import { createDM } from '../services/api/dms.js';
import { getShares, createShare, deleteShare, updateAttachment, getAttachment, updateShare } from '../services/api/attachments';
import { getAttachmentPublicShare, createAttachmentPublicShare, deleteAttachmentPublicShare } from '../services/api/attachment_public_shares.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SymbolBubble from './SymbolBubble.js';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import mixpanel from 'mixpanel-browser';
import styled, {keyframes} from 'styled-components';
import * as Sentry from '@sentry/react';
import CaseShareRecipientSelect from './CaseShareRecipientSelect.js';
import { createInvite } from '../services/api/invites.js';
import { useSelector } from 'react-redux';

function CaseShareModal(props) {
    const [groups, setGroups] = useState([]);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [shares, setShares] = useState([]);
    const [publicShare, setPublicShare] = useState(null);
    const [videoContainsPHI, setVideoContainsPHI] = useState(null);
    const [videoHasConsent, setVideoHasConsent] = useState(null);
    const [videoCanBeShared, setVideoCanBeShared] = useState(null);
    const [videoCanPublicShare, setVideoCanPublicShare] = useState(null);
    const [sharingMessage, setSharingMessage] = useState('');
    const [activeTab, setActiveTab] = useState('new')
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showShareSuccessMessage, setShowShareSuccessMessage] = useState(false);
    const [shareSuccessMessage, setShareSuccessMessage] = useState('');

    const self = useSelector(state => state.user.value);

    useEffect(() => {
        // console.log('getting groups for user ' + props.user.id)
        getAttachment(props.attachment.id)
        .then(attachment => {
            // console.log('got attachment', attachment)
            setVideoContainsPHI(attachment.contains_phi);
            setVideoHasConsent(attachment.with_patient_consent);
            if (attachment.contains_phi === null || attachment.with_patient_consent === null)
                setActiveTab('phi-info')
        })

        getGroups()
        .then(items => {
            setGroups(items);
        })

        getShares(props.attachment.id)
        .then(items => {
            // Promise.all(items.map(item => getGroupMembers(item.group_id)))
            // .then(members => {
                // items.forEach((item, idx) => {
                //     item.group.members = members[idx];
                // })
            //     setShares(items);
            // })
            setShares(items);
        })

        getAttachmentPublicShare(props.attachment.id)
        .then(item => {
            setPublicShare(item);
        }).catch((res) => {
            if (res.status === 404) {
                setPublicShare(null);
            }
        });
    }, [props.show])

    //useEffect to monitor sharing permissions
    useEffect(() => {
       setSharingEligibility();
       setSharedStatus(props.attachment.id);
    }, [videoContainsPHI, videoHasConsent, selectedUsers])

    //useEffect to get updated public share status
    useEffect(() => {
        if (videoContainsPHI && !videoHasConsent) {
            setPublicShare(null);
            setVideoCanPublicShare(false);
        } else {
            setVideoCanPublicShare(true);
        }
    }, [videoContainsPHI, videoHasConsent])

    //useEffect to fade out confirmation messages
    useEffect(() => {
        if (confirmationMessage.length > 0) {
            const timer = setTimeout(() => {
                setConfirmationMessage('');
            }, 3000)
        }
    }, [confirmationMessage])

    useEffect(() => {
            const timer = setTimeout(() => {
                setShowShareSuccessMessage(false);
            }, 3000)
    }, [showShareSuccessMessage])

    function isGroup (recipient) {
        // console.log('checking if group', recipient, recipient?.membership != null);
        return recipient?.membership != null;
    }

    function isUser (recipient) {
        // console.log('checking if user', recipient, recipient?.last_name != null);
        return recipient?.last_name != null;
    }

    function isInvite (recipient) {
        return recipient?.invite_address != null;
    }

    function setSharingEligibility() {
        if (videoContainsPHI == null || videoHasConsent == null){
            setSharingMessage('You must complete the PHI attestation for this video before you can share it on Orchid.');
            setVideoCanBeShared(false)
        }
        else if (selectedUsers.length == 1 && isGroup(selectedUsers[0])) {
            let group = selectedUsers[0];
            setVideoCanBeShared(checkSharingEligibility(group));
        }
        else if (selectedUsers.length > 0) {
            if (videoContainsPHI === false || videoHasConsent === true){
                setVideoCanBeShared(true);
                setSharingMessage('');
            } else {
                setVideoCanBeShared(false);
                setSharingMessage('Videos that contain PHI must be accompanied by patient consent to be shared with other Orchid Users.');
            }
        }
        else {
            setVideoCanBeShared(null);
            setSharingMessage('');
        }
    }

    function setSharedStatus (attachment_id) {
        getShares(attachment_id)
        .then(items => {
            // Promise.all(items.map(item => getGroupMembers(item.group_id)))
            // .then(members => {
            //     items.forEach((item, idx) => {
            //         item.group.members = members[idx];
            //     })
            //     setShares(items);
            // }) 
            setShares(items);
        })
    }

    function checkSharingEligibility (group) {
        if (group.allow_phi === false) {
            //no phi is allowed, ever
            if (videoContainsPHI === false && videoHasConsent != null ) {
                return true;
            }
            else {
                setSharingMessage('This group does not allow any PHI (even with pateint consent), so your video cannot be shared.');
                return false;
            }
        }
        else if (group.allow_phi === true && group.require_patient_consent === true) {
            //phi is allowed, but only with consent
            if (videoContainsPHI === false && videoHasConsent != null)
                //no phi, no problem
                return true;
            else if (videoContainsPHI === true && videoHasConsent === true)
                //phi, but with consent
                return true;
            else {
                //phi, but no consent
                setSharingMessage('This group requires patient consent to share PHI, so your video cannot be shared.');
                return false;
            }
        }
        else if (group.allow_phi === true && group.require_patient_consent === false) {
            //this is a care team group, so phi is allowed without consent
            if (videoContainsPHI != null && videoHasConsent != null)
                return true;
            else {
                return false;
            }
        }
        else
            return false;
    }

    const onPHIChange = (value) => {
        // console.log("Contains PHI: ", value)
        updateAttachment(props.attachment.id, { "contains_phi" : value }).then((response) => {
            if (response)
                setVideoContainsPHI(response.contains_phi)
            mixpanel.track('Updated Video PHI', 
                {
                    'Source': 'Viewer Page', 
                    'Attachment ID': props.attachment.id,
                    'Value': value
                }
            )
        })
    }

    const onConsentChange = (value) => {
        // console.log("Has consent: ", value)
        updateAttachment(props.attachment.id, { "with_patient_consent" : value }).then((response) => {
            if (response)
                setVideoHasConsent(response.with_patient_consent)
            mixpanel.track('Updated Video Consent', 
                {
                    'Source': 'Viewer Page', 
                    'Attachment ID': props.attachment.id,
                    'Value': value
                }
            )
        })
    }

    function renderInfoBubble (group) {
        if (group.allow_phi === false) {
            //no phi is allowed, ever
            return <SymbolBubble 
                        text="This group does not allow PHI." 
                        variant="info"
                    />
        }
        else if (group.allow_phi === true && group.require_patient_consent === true) {
            //phi is allowed, but only with consent
            return <SymbolBubble 
                        text="This group allows PHI with patient consent." 
                        variant="info"
                    />
        }
        else if (group.allow_phi === true && group.require_patient_consent === false) {
            //this is a care team group, so phi is allowed without consent
            return <SymbolBubble 
                        text="This group is a care team." 
                        variant="info"
                    />
        }
    }

    function setConfirmationMessageByIndex (idx, message) {
        setConfirmationMessage(() =>
        [
            ...confirmationMessage.slice(0, idx), 
           `\r\n` + message, 
            ...confirmationMessage.slice(idx + 1)
        ]
        )
    }

    function setShareByGroupId (group_id, share) {
        setShares(() => shares.map(item => {
            if (item.group_id === group_id)
                return share;
            else
                return item;
        }))
    }

    const [newSharePermissions, setNewSharePermissions] = useState({
        "can_edit": false,
        "can_enrich": false,
        "can_export": false
    });


    function createShareWithGroup (group) {
        createShare(props.attachment.id, { "group_id" : group.id, "can_edit" : newSharePermissions.can_edit, "can_enrich" : newSharePermissions.can_enrich, "can_export" : newSharePermissions.can_export })
        .then((response) => {
            setShareSuccessMessage('Your video has been shared with ' + group.name + '.')
            setShowShareSuccessMessage(true);
            try {
                mixpanel.track('Shared Video', 
                    {
                        'Source': 'Viewer Page', 
                        'Group ID': group.id, 
                        'Group Name': group.name,
                        'Attachment ID': props.attachment.id,
                        'Edit Permission': newSharePermissions.can_edit,
                        'Enrich Permission': newSharePermissions.can_enrich,
                        'Export Permission': newSharePermissions.can_export
                    }
                )
            } catch (e) {
                Sentry.captureException(e);
            }
            setSelectedUsers([]);
            setSharingMessage('');
            setNewSharePermissions({
                "can_edit": false,
                "can_enrich": false,
                "can_export": false
            });
            setSharedStatus(props.attachment.id);
        })
    }

    function createShareWithUsers (users) {
        createDM(users.map(user => user.id))
        .then((dmGroup) => {
            createShare(props.attachment.id, { "group_id" : dmGroup.id, "can_edit" : newSharePermissions.can_edit, "can_enrich" : newSharePermissions.can_enrich, "can_export" : newSharePermissions.can_export }).then((response) => {
                setShareSuccessMessage('Your video has been shared with ' + users.map((user, idx) => {
                    if (idx === 0)
                        if (user.first_name && user.last_name)
                            return user.first_name + ' ' + user.last_name;
                        else
                            return user?.email;
                    else if (idx === users.length - 1)
                        if (user.first_name && user.last_name)
                            return ' and ' + user.first_name + ' ' + user.last_name;
                        else
                            return ' and ' + user?.email;
                    else
                        if (user.first_name && user.last_name)
                            return ', ' + user.first_name + ' ' + user.last_name;
                        else
                            return ', ' + user?.email;
                }
                ) + '.')
                setShowShareSuccessMessage(true);
                try {
                    mixpanel.track('Shared Video', 
                        {
                            'Source': 'Viewer Page', 
                            'Group ID': dmGroup.id, 
                            'Group Name': dmGroup.name,
                            'Attachment ID': props.attachment.id,
                            'Number of Users': users.length,
                            'User IDs': users.map(user => user.id),
                            'Edit Permission': newSharePermissions.can_edit,
                            'Enrich Permission': newSharePermissions.can_enrich,
                            'Export Permission': newSharePermissions.can_export
                        }
                    )
                } catch (e) {
                    Sentry.captureException(e);
                }
                setSelectedUsers([]);
                setSharingMessage('');
                setNewSharePermissions({
                    "can_edit": false,
                    "can_enrich": false,
                    "can_export": false
                });
                setSharedStatus(props.attachment.id);
            })
        })
    }

    function handleShareButtonClick() {
        if (selectedUsers.length == 1 && isGroup(selectedUsers[0])) {
            createShareWithGroup(selectedUsers[0]);
        } else if (selectedUsers.length > 0) {
            // generate invites for any email addresses, add their user objects to the selectedUsers array
            let emails = selectedUsers.filter(user => isInvite(user)).map(user => user.invite_address);
            let users = selectedUsers.filter(user => isUser(user));
            
            // generate invites. createInvite returns a promise, so we need to wait for all of them to resolve
            let invitePromises = emails.map(email => createInvite(email));
            Promise.all(invitePromises).then((invites) => {
                // console.log(invites)
                let newUsers = invites.map(invite => invite.user);
                createShareWithUsers([...users, ...newUsers]);
            });
            emails.forEach(email => {
                try {
                    mixpanel.track('Sent Invite', 
                        {
                            'Source': 'Viewer Page', 
                            'Email Address': email,
                            'Invited With Share': true,
                            'Invited With Others': selectedUsers.length > 1,
                            'Attachment ID': props.attachment.id,
                            'Inviter ID': self.id,
                            'Inviter Email': self.email,
                        }
                    )
                }
                catch (e) {
                    Sentry.captureException(e);
                }
                recordInviteData(email);
            })

        }
    }

    async function recordInviteData (email) {
        const formData = new FormData();
        formData.append('EMAIL', email);
        formData.append('LIST', 'Invites');
        formData.append('INVITER', self.first_name + ' ' + self.last_name);

        try {
            const response = await fetch(
              "https://script.google.com/macros/s/AKfycbxMqWXWiHIa9zSy4LJ838bAsO4_toAWu6qtoMeLF9F57TzARoJP2_tHnh-dnYQZm58/exec", 
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded", // Use URL-encoded form data
                },
                body: new URLSearchParams(formData).toString(),
              }
            );
        
            if (response.ok) {
              // Handle success
              console.log("Form submitted successfully!");
            } else {
              // Handle failure
              console.error("Form submission failed.");
            }
          } catch (error) {
            // Handle network errors or exceptions
            console.error("Error submitting the form:", error);
          }
    }
    
    return (
    <CustomModal
        {...props}
        centered
        dialogClassName='share-modal'
    >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Share <b>{props.attachment?.subtitle ? props.attachment.subtitle : props.attachment.case.case_name}</b>
        </Modal.Title>
        </Modal.Header>
        <Tabs 
            id="share-tabs"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="mb-3"
        >
            <Tab eventKey="phi-info" title="PHI Disclosures">
                <Modal.Body>
                <div
                    style={{height: '300px'}}
                >
                    <SymbolBubble text="Changes may unshare this video from any groups or messages with incompatible PHI policies!" variant='warning'></SymbolBubble> &nbsp;
                    To share this case, you must complete the 
                    following attestations. 
                    <br/><br/>
                <Form>
                <Container>
                    <Row>
                    <Col xs={8}>
                    <span>Does this video or its title/description contain PHI?</span>
                    </Col>
                    <Col xs={2}>
                    <Form.Check
                        inline
                        checked={videoContainsPHI === true}
                        onChange={() => {onPHIChange(true)}}
                        type='radio'
                        name='phi-radio'
                        label={`Yes`}
                        id={`phi-radio-yes`}
                    />
                    </Col>
                    <Col xs={2}>
                    <Form.Check
                        inline
                        checked={videoContainsPHI === false}
                        onChange={(event) => {onPHIChange(false)}}
                        type='radio'
                        name='phi-radio'
                        label={`No`}
                        id={`phi-radio-no`}
                    />
                    </Col>
                    </Row>
                    <Row>
                    <Col xs={8}>
                    <span>Have you obtained the patient's written consent to disclose PHI?</span>
                    </Col>
                    <Col xs={2}>
                    <Form.Check
                        inline
                        checked={videoHasConsent === true}
                        onChange={() => onConsentChange(true)}
                        type='radio'
                        name='consent-radio'
                        label={`Yes`}
                        id={`consent-radio-yes`}
                    />
                    </Col>
                    <Col xs={2}>
                    <Form.Check
                        inline
                        checked={videoHasConsent === false}
                        onChange={() => onConsentChange(false)}
                        type='radio'
                        name='consent-radio'
                        label={`No`}
                        id={`consent-radio-no`}
                    />
                    </Col>
                </Row>
                </Container>
            </Form>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {setActiveTab('new')}}>Next</Button>
            </Modal.Footer>
            </Tab>
            {!props.no_share &&
            <Tab eventKey="new" title="Share">
                <Modal.Body>
                    <div
                        style={{height: '300px'}}
                    >
                    Share this case with Orchid users or groups. Add an email to invite someone!
                    <br/>
                    <br/>
                    <CaseShareRecipientSelect
                        groups={groups}
                        selectedRecipients={selectedUsers}
                        setSelectedRecipients={setSelectedUsers}
                    />
                    <div>
                        {sharingMessage.length>0 && 
                        <SymbolBubble 
                        text="Video cannot be shared with this recipient." 
                        variant="error"
                        />} &nbsp;
                        {sharingMessage.length>0 &&
                        sharingMessage
                        }
                    </div>
                    {selectedUsers.length > 0 && sharingMessage.length == 0 &&
                    <>
                    <Form
                        style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', padding:'0px'}}
                    >
                        Permissions:
                            <div
                                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', cursor: 'default', justifyContent: 'center'}}
                            >
                                <OverlayTrigger
                                    key={'watch'}
                                    placement='top'
                                    delay={{ show: 100, hide: 100 }}
                                    overlay={
                                        <Tooltip>
                                            Shared cases are always watchable by the recipient(s).
                                        </Tooltip>
                                    }
                                    >
                                    <span>
                                    <Form.Check
                                        key={'watch'}
                                        checked={true}
                                        disabled={true}
                                    />
                                    </span>
                                </OverlayTrigger>
                            Watch
                            </div>
                        <OverlayTrigger
                            key={'edit'}
                            placement='top'
                            delay={{ show: 100, hide: 100 }}
                            overlay={
                                <Tooltip>
                                    Allows the recipient(s) to edit and chapterize the video.
                                </Tooltip>
                            }
                        >
                            <Form.Check
                                checked={newSharePermissions.can_edit}
                                disabled={false}
                                key="edit"
                                type="checkbox"
                                onChange={() => setNewSharePermissions({can_edit: !newSharePermissions.can_edit, can_enrich: !newSharePermissions.can_enrich, can_export: newSharePermissions.can_export})}
                                label="Edit"
                            />
                        </OverlayTrigger>
                        <OverlayTrigger
                            key={'export'}
                            placement='top'
                            delay={{ show: 100, hide: 100 }}
                            overlay={
                                <Tooltip>
                                    Allows the recipient(s) to export the video from the Orchid platform.
                                </Tooltip>
                            }
                        >
                            <Form.Check
                                checked={newSharePermissions.can_export}
                                disabled={false}
                                key="export"
                                type="checkbox"
                                onChange={() => setNewSharePermissions({can_edit: newSharePermissions.can_edit, can_enrich: newSharePermissions.can_enrich, can_export: !newSharePermissions.can_export})}
                                label="Export"
                            />
                        </OverlayTrigger>
                    </Form>
                    </>
                    }
                    <br/>
                    <div
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                    {showShareSuccessMessage ?
                        <FadeText>
                            {shareSuccessMessage}
                        </FadeText>
                        :
                        selectedUsers.length > 1 ?
                        <Button
                            onClick = {handleShareButtonClick}
                            disabled = {!videoCanBeShared}
                        >
                            Share With Group
                        </Button> : selectedUsers.length == 1 &&
                        <Button
                            onClick = {handleShareButtonClick}
                            disabled = {!videoCanBeShared}
                        >
                            Share
                        </Button>

                    }
                    </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Done</Button>
                </Modal.Footer>
            </Tab>
            }
            {!props.no_share &&
            <Tab eventKey="public" title="Public Sharing">
                <Modal.Body>
                    <div style={{height: '300px'}}>
                    <p>
                        <SymbolBubble
                            variant='warning'
                            text='This case will be viewable to anyone who uses this link, and anyone who views with this link will lose access if public sharing is disabled.'
                        /> &nbsp;&nbsp;
                        Enable Public Sharing to share your case with just a link.
                    </p>
                    <Form.Check type="switch" id="mfa-switch" label="Enable public sharing?" disabled={!videoCanPublicShare} checked={publicShare != null} onClick={(e) => {
                        if (publicShare && publicShare.is_active != false) {
                            deleteAttachmentPublicShare(props.attachment.id).then(() => {
                                setPublicShare(null);
                                mixpanel.track('Unshared Public Video', 
                                    {
                                        'Source': 'Viewer Page', 
                                        'Attachment ID': props.attachment.id
                                    }
                                )
                            })
                        } else {
                            createAttachmentPublicShare(props.attachment.id).then((item) => {
                                setPublicShare(item);
                                mixpanel.track('Shared Public Video', 
                                    {
                                        'Source': 'Viewer Page', 
                                        'Attachment ID': props.attachment.id
                                    }
                                )
                            })
                        }
                    }} />
                    {!videoCanPublicShare &&
                    <div>
                        <hr />
                        <SymbolBubble 
                        text="Video cannot be publicly shared." 
                        variant="error"
                        /> &nbsp;
                        This video cannot be shared publicly because it contains PHI and you have not consented the patient for its disclosure.
                    </div>
                    }
                    {publicShare &&
                    <LinkDisplay link={publicShare.link} />
                    }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Done</Button>
                </Modal.Footer>
            </Tab>
            }
            {!props.no_share &&
            <Tab eventKey="groups" title="Manage Sharing Permissions">
                <Modal.Body>
                    <div
                        style={{margin: '0px', padding: '0px'}}
                    >Shared cases are always watchable by the recipient(s).</div>
                    <br/>
                    <div
                        style={{height: '232px', overflowY: 'hidden'}}
                    >
                    
                    <Form
                        style={{overflowY: 'scroll', height: '100%'}}
                    >
                        <Table
                            style={{textAlign: 'center'}}
                        >
                            <thead
                                style={{position: 'sticky', top: '0px', backgroundColor: '(0,0,0,1)', zIndex: '1'}}
                            >
                                <tr>
                                    <th
                                        style={{textAlign: 'left'}}
                                    >
                                        Recipient
                                    </th>
                                    {/* <th>
                                        Watch
                                    </th> */}
                                    <th>
                                        Edit
                                    </th>
                                    <th>
                                        Export
                                    </th>
                                    <th
                                        style={{whiteSpace: 'nowrap'}}
                                    >
                                        Remove Share
                                    </th>
                                </tr>
                            </thead>
                            {shares.map((share, idx) => {
                                // let isShared = isSharedWithGroup[idx];
                                // let isEligible = videoCanBeShared[idx];
                                // let share = shares.find(share => share.group_id === group.id);
                                return (
                                    <tr>
                                        <td
                                            style={{textAlign: 'left'}}
                                        >
                                            <div>
                                                {share.group.is_dm ?
                                                share.group.members.filter(member => member.user.id !== self.id).map((member, idx) => {
                                                    if (idx === share.group.members.length - 2)
                                                        if (member.user.first_name && member.user.last_name)
                                                            return member.user.first_name + ' ' + member.user.last_name;
                                                        else
                                                            return member.user?.email;
                                                    else 
                                                        if (member.user.first_name && member.user.last_name)
                                                            return  member.user.first_name + ' ' + member.user.last_name + ', ';
                                                        else
                                                            return  member.user?.email + ', ';
                                                }).reduce((acc, curr) => acc + curr) :
                                                share.group.name}
                                            </div>
                                        </td>
                                        <td>
                                            <OverlayTrigger
                                                key={share.id}
                                                placement='top'
                                                delay={{ show: 100, hide: 100 }}
                                                overlay={
                                                    <Tooltip>
                                                        Allows the recipient(s) to edit and chapterize the video.
                                                    </Tooltip>
                                                }
                                            >
                                                <Form.Check
                                                    checked={share?.can_edit}
                                                    disabled={false}
                                                    key="{group.id}-edit"
                                                    type="checkbox"
                                                    onChange={() => {
                                                        if (share?.can_edit) {
                                                            updateShare(props.attachment.id, share.group_id, { "can_edit" : false })
                                                            .then((share) => {
                                                                setConfirmationMessageByIndex(idx, 'Group permissions updated.');
                                                                setShareByGroupId(share.group_id, share);
                                                            })
                                                            updateShare(props.attachment.id, share.group_id, { "can_enrich" : false }).then((share) => {
                                                                setConfirmationMessageByIndex(idx, 'Group permissions updated.');
                                                                setShareByGroupId(share.group_id, share);
                                                            })
                                                        } else {
                                                            updateShare(props.attachment.id, share.group_id, { "can_edit" : true }).then((share) => {
                                                                setConfirmationMessageByIndex(idx, 'Group permissions updated.');
                                                                setShareByGroupId(share.group_id, share);
                                                            })
                                                            updateShare(props.attachment.id, share.group_id, { "can_enrich" : true }).then((share) => {
                                                                setConfirmationMessageByIndex(idx, 'Group permissions updated.');
                                                                setShareByGroupId(share.group_id, share);
                                                            })
                                                        }
                                                    }}
                                                />
                                            </OverlayTrigger>
                                        </td>
                                        <td>
                                            <OverlayTrigger
                                                key={share.id}
                                                placement='top'
                                                delay={{ show: 100, hide: 100 }}
                                                overlay={
                                                    <Tooltip>
                                                        Allows the recipient(s) to export the video from the Orchid platform.
                                                    </Tooltip>
                                                }
                                            >
                                                <Form.Check
                                                    checked={share?.can_export}
                                                    disabled={false}
                                                    key="{group.id}-export"
                                                    type="checkbox"
                                                    onChange={() => {
                                                        if (share?.can_export) {
                                                            updateShare(props.attachment.id, share.group_id, { "can_export" : false }).then((share) => {
                                                                setConfirmationMessageByIndex(idx, 'Group permissions updated.');
                                                                setShareByGroupId(share.group_id, share);
                                                            })
                                                        } else {
                                                            updateShare(props.attachment.id, share.group_id, { "can_export" : true }).then((share) => {
                                                                setConfirmationMessageByIndex(idx, 'Group permissions updated.');
                                                                setShareByGroupId(share.group_id, share);
                                                            })
                                                        }
                                                    }}
                                                />
                                            </OverlayTrigger>
                                        </td>
                                        <td>
                                        <OverlayTrigger
                                                key={share.id}
                                                placement='top'
                                                delay={{ show: 100, hide: 100 }}
                                                overlay={
                                                    <Tooltip>
                                                        Unshare this video with this recipient.
                                                    </Tooltip>
                                                }
                                            >
                                            <CloseButton
                                                onClick={() => {
                                                    deleteShare(props.attachment.id, share.group_id).then(() => {
                                                        setConfirmationMessageByIndex(idx, 'Your video has been unshared with ' + share.group.name + '.')
                                                        mixpanel.track('Unshared Video', 
                                                            {
                                                                'Source': 'Viewer Page', 
                                                                'Group ID': share.group_id, 
                                                                'Group Name': share.group.name,
                                                                'Attachment ID': props.attachment.id
                                                            }
                                                        )
                                                    })
                                                    
                                                    setShares(() => shares.filter(item => item.group_id !== share.group_id));
                                                }}
                                            />
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                );
                            })}
                            <br/>
                        </Table>
                    </Form>
                    </div>
                    <div
                        style={{height:'20px', textAlign: 'center'}}
                    >
                        {confirmationMessage}
                    </div>
                    {/* <div> Want to share this video with another Orchid user? Take me to <Link to='/messages/' style={{fontWeight: 'bold', color: 'blue'}}>Messages</Link>!</div> */}
                    </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Done</Button>
                </Modal.Footer>
            </Tab>
            }
        </Tabs>
    </CustomModal>
    );
}

const LinkDisplay = ({link}) => {

    const [copied, setCopied] = useState(false);

    const onClick = (event) => {
        event.preventDefault();
        navigator.clipboard.writeText(link);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 10000);
    }

    return (
        <LinkContainer onClick={onClick}>
            <LinkText>{link}</LinkText>
            <div style={{right: '10px', fontSize: '.8em'}}>
                {copied ? 'Copied to clipboard!' : 'Click to copy'}
            </div>
        </LinkContainer>
    )
}

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const FadeText = styled.div`
  animation: ${fadeOut} 3s ease-in-out forwards;
`;

const CustomModal = styled(Modal)`
  .modal-dialog {
    min-width: 650px; /* Set custom width here */
  }
`;

const LinkContainer = styled.div`
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
        background-color: #e0e0e0;
    }
`;

const LinkText = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: 'Courier', sans-serif;
    font-size: .9em;
`;

export default CaseShareModal;


