import { get, post, del } from './common_no_err_handling.js';
import { del as del2 } from './common.js';


export function getAttachmentPublicShare(attachment_id) {
    return get(`/attachments/${attachment_id}/public/`);
}

export function createAttachmentPublicShare(attachment_id) {
    return post(`/attachments/${attachment_id}/public/`);
}

export function deleteAttachmentPublicShare(attachment_id) {
    return del2(`/attachments/${attachment_id}/public/`);
}

export function getAttachmentPublicShareByCode(share_code) {
    return get(`/public_share/${share_code}/`);
}

export function activateAttachmentPublicShare(share_code) {
    return post(`/public_share/${share_code}/`);
}
