import React, { useState, useEffect, useRef } from "react";
import { Navigate, Link } from "react-router-dom";
import { styled } from "styled-components";
import { getAttachments } from "../services/api/attachments.js";
import { getGroups, getSharedAttachments } from "../services/api/groups.js"
import { dateFromTimestamp, reduceString } from "../services/util/metadata.js";
import VideoList from "./VideoList.js";
import MobileVideoList from "./MobileVideoList.js";
import Carousel from 'react-bootstrap/Carousel';
import { useSelector } from 'react-redux';
import useWindowSize from "../hooks/useWindowSize.js";
import UserIcon from "./UserIcon.js";
import MobileHeader from "./MobileHeader.js";
import { getDMs } from "../services/api/dms.js";

const Home = (props) => {
    
    const user = useSelector(state => state.user.value);
    const [userAttachments, setUserAttachments] = useState([]);
    const [groupAttachments, setGroupAttachments] = useState([]);
    const [userAttachmentsLoading, setUserAttachmentsLoading] = useState(true);
    const [groupAttachmentsLoading, setGroupAttachmentsLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const mounted = useRef(true);
    const windowSize = useWindowSize();

    React.useEffect(() => {
        document.title = "Orchid Cloud";
      }, []);

    useEffect(() => {
        if (mounted.current) {
            //get the most recent attachments
            getAttachments({'per_page': 15}).then(items => {
                //keep only the items for which the user is the owner
                items = items.filter((item) => item.case.owner.id === user.id);
                //keep only the attachments with a status of 2 or higher
                items = items.filter((item) => item.status >= 2);
                setUserAttachments(items);
            })
            .then(() => {
                setUserAttachmentsLoading(false);
            })
            .catch(error => {
            console.error('There was a problem with the Fetch operation:', error);
            })

            //get the list of groups to which the user belongs
            // getGroups().then(items => {
            //     console.log(items);
            //     setGroups(items);
            //     return items;
            // })
            
            //get both groups and dms
            Promise.all([getGroups(), getDMs()])
            .then(([groupitems, dmitems]) => {
                setGroups(groupitems);
                return {groupitems, dmitems};
            })
            .then( ({groupitems, dmitems}) => {

                //create a list of promises to get the attachments for each group and dm
                let groupPromises = groupitems.map((group) => getSharedAttachments(group.id));
                let dmPromises = dmitems.map((dm) => getSharedAttachments(dm.id));

                //combine the promises into a single array
                let promises = groupPromises.concat(dmPromises);

                //execute all the promises
                Promise.all(promises).then((attachments) => {
                    //flatten the array of arrays
                    attachments = attachments.flat();

                    //destructure the shares
                    attachments = attachments.map((item) => item.attachment);

                    //keep only the attachments shared by another user
                    attachments = attachments.filter((item) => item.case.owner.id !== user.id);

                    //set the group field of each attachment
                    attachments.forEach((item) => {
                        let group = groupitems.find((group) => group.id === item.group_id);
                        item.group = group;
                    });

                    //add this group's attachments to the list of group attachments
                    setGroupAttachments(prevState => prevState.concat(attachments).reverse());
                })
                .then(() => {
                    setGroupAttachmentsLoading(false);
                }) 
            })
            .catch(error => {
               console.error('There was a problem with the Fetch operation:', error);
            })
        }
        return () => mounted.current=false;
    }, [])

    return ( 
        <Container>
            {windowSize.width > 900 ?
            <>
            <Dashboard>
                <StyledCarousel data-bs-theme="dark" controls={false}>
                    <Carousel.Item>
                        <h1>Welcome, {user.first_name}!</h1>
                        <p>Here, you can review, edit, and share your videos.</p>
                    </Carousel.Item>
                    <Carousel.Item>
                        <h3>Hit <img src='/images/icons/share-01.svg' alt='logo' height='30' style={{paddingBottom: '5px'}}/> Upload<br/>to add your recent captures.</h3>
                        <p>Then, annotate them and share them with your groups!</p>
                    </Carousel.Item>
                </StyledCarousel>
            </Dashboard>
            <RecentVideos>
                {(windowSize.width > 900) ? (
                <>
                <VideoList 
                    attachments={userAttachments} 
                    title="Your Recent Uploads"
                    emptyMessage="Your uploads will appear here when you upload a video."
                    isLoading={userAttachmentsLoading}
                />
                <VideoList 
                    attachments={groupAttachments} 
                    title="Recently Shared With You"
                    emptyMessage="Shared videos will appear here when you join a group."
                    isLoading={groupAttachmentsLoading}
                />
                </>
                 ) : (
                    <VideoList 
                    attachments={[...userAttachments, ...groupAttachments]} 
                    title="Recent Videos"
                    emptyMessage="Upload a video or join a group!"
                    isLoading={userAttachmentsLoading || groupAttachmentsLoading}
                    />
                 )
                }
            </RecentVideos>
            </>
            :
            <>
            <MobileHeader text='Orchid Surgical'/>
            <MobileVideoList 
                attachments={[...userAttachments, ...groupAttachments]} 
                emptyMessage="Upload a video or join a group!"
                isLoading={userAttachmentsLoading || groupAttachmentsLoading}
            />
            </>
            }
        </Container>
     );
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px;
    overflow-y: auto;
    width: 100%;
    height: 100%;

    @media (max-width: 899px) {
        padding: 0;
        height: 100%;
    }
`;

const Dashboard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(35vh - 120px);
    min-height: 180px;
`;

const RecentVideos = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    min-height: 65vh;
    overflow: visible;
    max-width: 1920px;
    margin: auto;
    /* gap: 20px; */

    & > div {
        flex: 1;
        max-width: 50%; /* Set a fixed width for each column */
        padding: 0 10px; /* Add some padding between columns */
    }
`;

const ThumbnailWrapper = styled.div`
    padding-top: 56.25%;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    border: 3px solid rgba(200, 200, 200, 1);

    img {
        inset: 0px;
        display: block;
        height: 100%;
        object-fit: cover;
        opacity: 1;
        position: absolute;
        transition: opacity 500ms ease-in-out;
        width: 100%;
        z-index: 1;
        top: 0;
    }

    &:hover {
        transform: scale(1.05);
        border-color: rgb(249, 249, 249, 0.8)
    }
`;
 
const VideoTooltip = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    margin: 10px;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: 2;
    cursor: pointer;
    color: white;
    &:hover {
        opacity: 1;
    }

    p {
        margin: 0;
    }
`;

const StyledCarousel = styled(Carousel)`
    width: 600px;
    height: 100px;

    .carousel-indicators {
        position: absolute;
        bottom: -45px;
    }
`;

export {Home as default, ThumbnailWrapper, VideoTooltip};